<template>
  <div>
    <TutoratPlanningHeader
      :meetings="meetings.length"
      :tuteurs="tuteurs.length"
      :meetings-today="meetingsToday.length"
    ></TutoratPlanningHeader>
    <v-card
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title class="d-flex justify-space-between">
        <a
          class="gray"
        >
          Planning de Tutorat
        </a>
        <v-icon
          top
          right
        >
          {{ icons.mdiCalendarClock }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-btn-toggle
          v-model="type"
          borderless
          group
        >
          <v-btn value="month">
            Mois
          </v-btn>

          <v-btn value="week">
            Semaine
          </v-btn>

          <v-btn value="day">
            Jour
          </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="meetingDialog = true"
        >
          Nouveau RDV
        </v-btn>
      </v-card-text>
      <v-divider>
      </v-divider>
      <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
        </v-btn>
        <v-spacer>
        </v-spacer>
        <span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
        <v-spacer>
        </v-spacer>
        <v-select
          v-model="selectedTuteur"
          class="ma-2"
          label="Filtrer par tuteur"
          multiple
          :items="tuteurs"
          chips
          dense
          attach
          outlined
          item-text="displayName"
          item-value="id"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              close
              class="pa-2"
              :color="solveColor(item)"
              @click:close="unfilterTuteur(item)"
            >
              <span>{{ item.displayName }}</span>
            </v-chip>
          </template>
        </v-select>
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
        >
          <v-icon>{{ icons.mdiChevronRight }}</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet>
        <v-calendar
          ref="calendar"
          v-model="cal"
          interval-count="15"
          interval-width="90"
          first-time="08:00"
          mode="stack"
          event-name="nom"
          event-timed="timed"
          locale="fr"
          color="primary"
          :type="type"
          :events="displayMeetings"
          :event-height="20"
          @click:event="showEvent"
          @click:date="dayView"
          @click:time="setTime"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <tutorat-planning-meeting-card-time-off
            v-if="selectedEvent.type === 'timeoff'"
            :meeting="selectedEvent"
            @closeOpen="selectedOpen = false"
            @meetingDeleted="meetingDeleted"
            @editMeetingDialogOpen="editMeetingDialogOpen"
          >
          </tutorat-planning-meeting-card-time-off>

          <tutorat-planning-meeting-card-visio
            v-else
            :meeting="selectedEvent"
            @closeOpen="selectedOpen = false"
            @meetingDeleted="meetingDeleted"
            @editMeetingDialogOpen="editMeetingDialogOpen"
          >
          </tutorat-planning-meeting-card-visio>
        </v-menu>
      </v-sheet>
    </v-card>
    <NewMeetingDialog
      :meeting-dialog="meetingDialog"
      :time-set="timeSet"
      @closeDialog="meetingDialog = false"
      @meetingAdded="meetingAdded"
    ></NewMeetingDialog>
    <EditMeetingDialog
      :edit-meeting-dialog="editMeetingDialog"
      :meeting-id="selectedEventEditId"
      :student-id="selectedEventStudentId"
      @closeEditDialog="editMeetingDialog = false"
      @meetingUpdated="updateMeeting"
    ></EditMeetingDialog>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight, mdiCalendarClock } from '@mdi/js'
import NewMeetingDialog from '@/components/NewMeetingDialog.vue'
import EditMeetingDialog from '@/components/EditMeetingDialog.vue'
import TutoratPlanningHeader from './TutoratPlanningHeader.vue'
import TutoratPlanningMeetingCardTimeOff from './TutoratPlanningMeetingCardTimeOff.vue'
import TutoratPlanningMeetingCardVisio from './TutoratPlanningMeetingCardVisio.vue'

export default {
  components: {
    TutoratPlanningHeader,
    TutoratPlanningMeetingCardTimeOff,
    TutoratPlanningMeetingCardVisio,
    NewMeetingDialog,
    EditMeetingDialog,
  },
  data() {
    return {
      displayMeetings: [],
      selectedEventEditId: null,
      selectedEventStudentId: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: 'month',
      loading: true,
      cal: '',
      tuteurs: [],
      formations: [],
      selectedTuteur: [],
      meetings: [],
      meetingDialog: false,
      editMeetingDialog: false,
      timeSet: {
        date: '',
        hours: '',
        minutes: '',
      },
      colors: [
        '#3F51B5',
        '#009688',
        '#F44336',
        '#4CAF50',
        '#E91E63',
        '#FF9800',
      ],
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiCalendarClock,
      },
    }
  },
  computed: {
    meetingsToday() {
      const dateToday = new Date()
      const dateStart = dateToday.setHours(0, 0, 0, 0)
      const dateEnd = dateToday.setHours(23, 59, 59, 0)
      const meetingsToday = this.meetings.filter(x => new Date(x.start) >= dateStart && new Date(x.start) <= dateEnd)

      return meetingsToday
    },
  },
  watch: {
    meetings() {
      this.updateMeetingsToDisplay()
    },
    selectedTuteur() {
      this.updateMeetingsToDisplay()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchTuteurs(), this.fetchFormations()])
        .then(values => {
          [this.tuteurs, this.formations] = values
          this.tuteurs.forEach(e => {
            this.selectedTuteur.push(e.id)
          })
          this.loading = false
        })
        .catch(err => console.log(err))
        .finally()
    },
    async fetchTuteurs() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/users-permissions/userlist?status=tuteur`)

      return res.data
    },
    async fetchFormations() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations-light`)

      return res.data
    },

    dayView(e) {
      this.type = 'day'
      this.cal = e.date
    },
    setTime(e) {
      this.timeSet = {
        date: e.date,
        hours: e.hour,
        minutes: e.minute,
      }
    },
    meetingAdded(payload) {
      this.meetings.push(payload)
    },
    solveColor(users) {
      const matchingId = element => element.id === users.id
      const index = this.tuteurs.findIndex(matchingId)

      return this.colors[index]
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    meetingDeleted(payload) {
      this.meetings = this.meetings.filter(x => x.id !== payload)
    },
    editMeetingDialogOpen(payload) {
      const [matchingMeeting] = this.meetings.filter(x => x.id === payload)
      this.editMeetingDialog = true
      this.selectedEventStudentId = matchingMeeting.students[0].id
      this.selectedEventEditId = payload
    },
    updateMeeting(payload) {
      const meetings = [...this.meetings]
      const foundIndex = this.meetings.findIndex(x => x.id === payload.id)

      meetings[foundIndex] = payload
      this.meetings = meetings
    },
    updateRange({ start, end }) {
      this.loading = true
      const startDate = new Date(new Date(start.date).setHours(0, 0, 0, 0)).toISOString()
      const endDate = new Date(new Date(end.date).setHours(23, 59, 59, 0)).toISOString()
      this.$http.get(`${process.env.VUE_APP_API_URL}/meetings?start_gte=${startDate}&start_lte=${endDate}`).then(res => {
        this.meetings = res.data
        this.loading = false
      })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    unfilterTuteur(item) {
      const index = this.selectedTuteur.indexOf(item.id)
      if (index > -1) {
        this.selectedTuteur.splice(index, 1)
      }
    },
    updateMeetingsToDisplay() {
      if (!this.loading) {
        const meetings = [...this.meetings]
        meetings.forEach(meeting => {
          [meeting.user] = meeting.users
          meeting.name = meeting.nom
          meeting.start = new Date(meeting.start)
          meeting.end = new Date(meeting.end)
          meeting.timed = true
          if (meeting.type === 'timeoff') {
            meeting.color = 'secondary'
          } else {
            meeting.color = this.solveColor(meeting.users[0])
          }
          if (meeting.students.length > 0) {
            const matchingFormation = this.formations.find(x => x.id === meeting.students[0].formation)
            if (matchingFormation) {
              meeting.students[0].formation = matchingFormation.nom
            }
          }
        })

        if (this.selectedTuteur.length > 0) {
          this.displayMeetings = meetings.filter(x => this.selectedTuteur.includes(x.user.id))

          return
        }

        this.displayMeetings = []
      }

      this.displayMeetings = []
    },
  },
}
</script>

<style>
.gray {
  color: rgba(94, 86, 105, 0.68) !important
}
</style>
